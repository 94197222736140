// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-web {
  width: 100%;
  display: flex;
  height: 480px;
  border-radius: 50px 180px 50px 180px;
}
.banner-web .btn-content {
  width: 60%;
  padding: 68px 0 0 68px;
}
.banner-web .btn-image {
  position: absolute;
  top: 106px;
  right: 82px;
}
.banner-web .btn-image img {
  width: 31.25rem;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Banner/Banner.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,oCAAA;AACJ;AAAI;EACI,UAAA;EACA,sBAAA;AAER;AAAI;EACI,kBAAA;EACA,UAAA;EACA,WAAA;AAER;AADQ;EACI,eAAA;EACA,YAAA;AAGZ","sourcesContent":[".banner-web {\n    width: 100%;\n    display: flex;\n    height: 480px;\n    border-radius: 50px 180px 50px 180px;\n    .btn-content {\n        width: 60%;\n        padding: 68px 0 0 68px;\n    }\n    .btn-image {\n        position: absolute;\n        top: 106px;\n        right: 82px;\n        img{\n            width: 31.25rem;\n            height: auto;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
