// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-box-left {
  min-height: 278px;
  max-width: 500px;
  border-radius: 30px 100px;
  background-image: linear-gradient(to right, #ffd88d, #c9ffc5);
  padding: 40px;
  overflow: hidden;
}

.about-box-right {
  min-height: 278px;
  max-width: 500px;
  border-radius: 100px 30px;
  background-image: linear-gradient(to right, #aefeb5, #adf6f9);
  padding: 40px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.scss"],"names":[],"mappings":"AASA;EARI,iBASmB;EARnB,gBAQ0B;EAP1B,yBAOiC;EANjC,6DAAA;EACA,aAK+D;EAJ/D,gBAAA;AACJ;;AAMA;EAZI,iBAamB;EAZnB,gBAY0B;EAX1B,yBAWiC;EAVjC,6DAAA;EACA,aAS+D;EAR/D,gBAAA;AAUJ","sourcesContent":["@mixin about-box($height, $max-width, $border-radius, $gradient-color1, $gradient-color2, $padding) {\n    min-height: $height;\n    max-width: $max-width;\n    border-radius: $border-radius;\n    background-image: linear-gradient(to right, $gradient-color1, $gradient-color2);\n    padding: $padding;\n    overflow: hidden;\n}\n\n.about-box-left {\n    @include about-box(278px, 500px, 30px 100px, #ffd88d, #c9ffc5, 40px);\n}\n\n.about-box-right {\n    @include about-box(278px, 500px, 100px 30px, #aefeb5, #adf6f9, 40px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
