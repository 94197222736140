// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutorial-wrapper-left {
  max-width: 300px;
  margin-top: 50px;
  position: relative;
}

.tutorial-wrapper {
  margin-top: 50px;
  max-width: 300px;
  position: relative;
}

.tutorial-icon {
  margin: 20px 0 20px 70px;
}

.tutorial-color[data-color=D7B1FF] {
  position: absolute;
  background-color: #d7b1ff;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: -1;
  filter: blur(50px);
  opacity: 0.5;
}

.tutorial-color[data-color="71FFB6"] {
  position: absolute;
  background-color: #71ffb6;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: -1;
  filter: blur(50px);
  opacity: 0.5;
}

.tutorial-color[data-color=FFCC97] {
  position: absolute;
  background-color: #ffcc97;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: -1;
  filter: blur(50px);
  opacity: 0.5;
}

@media screen and (min-width: 992px) {
  .tutorial-top {
    float: right;
    margin-top: -80px;
  }
  .tutorial-item-top {
    margin-top: -170px;
  }
}
@media screen and (min-width: 1200px) {
  .tutorials {
    margin-right: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Products/PaymentGateways/PaymentGateways.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AACA;EACI,gBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,wBAAA;AAGJ;;AAaA;EAZI,kBAAA;EACA,yBAY6B;EAX7B,SAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAGJ;;AAGA;EAfI,kBAAA;EACA,yBAe6B;EAd7B,SAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAgBJ;;AAPA;EAlBI,kBAAA;EACA,yBAkB6B;EAjB7B,SAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AA6BJ;;AAhBA;EACI;IACI,YAAA;IACA,iBAAA;EAmBN;EAjBE;IACI,kBAAA;EAmBN;AACF;AAhBA;EACI;IACI,mBAAA;EAkBN;AACF","sourcesContent":[".tutorial-wrapper-left {\n    max-width: 300px;\n    margin-top: 50px;\n    position: relative;\n}\n.tutorial-wrapper {\n    margin-top: 50px;\n    max-width: 300px;\n    position: relative;\n}\n.tutorial-icon {\n    margin: 20px 0 20px 70px;\n}\n\n@mixin tutorial-color-main($background-color) {\n    position: absolute;\n    background-color: $background-color;\n    bottom: 0;\n    right: 0;\n    width: 200px;\n    height: 200px;\n    border-radius: 50%;\n    z-index: -1;\n    filter: blur(50px);\n    opacity: 0.5;\n}\n\n.tutorial-color[data-color=\"D7B1FF\"] {\n    @include tutorial-color-main(#d7b1ff);\n}\n.tutorial-color[data-color=\"71FFB6\"] {\n    @include tutorial-color-main(#71ffb6);\n}\n.tutorial-color[data-color=\"FFCC97\"] {\n    @include tutorial-color-main(#ffcc97);\n}\n\n@media screen and (min-width: \"992px\") {\n    .tutorial-top {\n        float: right;\n        margin-top: -80px;\n    }\n    .tutorial-item-top {\n        margin-top: -170px;\n    }\n}\n\n@media screen and (min-width: \"1200px\") {\n    .tutorials {\n        margin-right: 100px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
