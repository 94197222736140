// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-contact {
  min-width: 300px;
  width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/Contact.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;AACJ","sourcesContent":[".form-contact {\n    min-width: 300px;\n    width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
