// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icon/footer.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-home1 {
  margin-top: 100px;
}

.home2 {
  background-color: #000000;
  padding: 20px 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: top 200px center;
  background-repeat: no-repeat;
}

.home-box {
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to right, #ffefbd, #ebc1f5);
  border-radius: 30px;
}

.home-box-item {
  display: flex;
}

.wrapper-box {
  max-width: 650px;
}

.box-item {
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 25px;
}
.box-item img {
  margin-right: 10px;
}

.partner-item-img {
  width: 90px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AACA;EACI,yBAAA;EACA,eAAA;EACA,yDAAA;EACA,qCAAA;EACA,4BAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,6DAAA;EACA,mBAAA;AAGJ;;AAAA;EACI,aAAA;AAGJ;;AAAA;EACI,gBAAA;AAGJ;;AADA;EACI,kBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AAIJ;AAHI;EACI,kBAAA;AAKR;;AADA;EACI,WAAA;EACA,YAAA;AAIJ","sourcesContent":[".section-home1 {\n    margin-top: 100px;\n}\n.home2 {\n    background-color: #000000;\n    padding: 20px 0;\n    background-image: url(\"../../assets/icon/footer.png\");\n    background-position: top 200px center;\n    background-repeat: no-repeat;\n}\n.home-box {\n    padding: 50px 20px;\n    display: flex;\n    justify-content: center;\n    background-image: linear-gradient(to right, #ffefbd, #ebc1f5);\n    border-radius: 30px;\n}\n\n.home-box-item {\n    display: flex;\n}\n\n.wrapper-box {\n    max-width: 650px;\n}\n.box-item {\n    padding: 10px 20px;\n    background-color: #fff;\n    border-radius: 20px;\n    margin-top: 25px;\n    img {\n        margin-right: 10px;\n    }\n}\n\n.partner-item-img {\n    width: 90px;\n    height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
