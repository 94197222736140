// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icon/footer.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #000;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: right center;
  padding: 40px 0;
  width: 100%;
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
  color: #6b6d6f;
}

.desc {
  margin-top: 25px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.desc li > p {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,yDAAA;EACA,4BAAA;EACA,iCAAA;EACA,eAAA;EACA,WAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AAEJ;;AAAA;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAGJ;AAFI;EACI,gBAAA;AAIR","sourcesContent":["footer {\n    background-color: #000;\n    background-image: url(\"../../assets/icon/footer.png\");\n    background-repeat: no-repeat;\n    background-position: right center;\n    padding: 40px 0;\n    width: 100%;\n}\n.title {\n    font-size: 14px;\n    font-weight: 600;\n    margin-top: 30px;\n    color: #6b6d6f;\n}\n.desc {\n    margin-top: 25px;\n    color: #fff;\n    font-size: 13px;\n    font-weight: 500;\n    li > p {\n        margin-top: 15px;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
